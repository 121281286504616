import * as React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { createUseStyles } from 'react-jss'

const PolicyPage = (props) => {
  const classes = useStyles()
  const { translations, path, } = props
  return (
  <Layout path={path}>
    <Seo title={translations.page_title} description={translations.page_description} />
      <div className={classes.pageTitle}>
        <div className={classes.pageTitleWrapper}>
          <h1>{translations.page_title}</h1>
        </div>
      </div>
      <div className={classes.policyPage}>
        <p className={classes.policyText}>
          {translations.general_data.title}
        </p>
        <p className={classes.policyText}>
          {translations.collected_data.title}
        </p>
        <p className={classes.policyText}>
          {translations.data_security.title}
        </p>
        <p className={classes.policyText}>
          {translations.wrap_up.title}
        </p>
        <p className={classes.policyText}>
          {translations.section_5.title}
        </p>
        <p className={classes.policyText}>
          {translations.section_6.title}
        </p>
        <p className={classes.policyText}>
          {translations.section_7.title}
        </p>
        <p className={classes.policyText}>
          {translations.section_8.title}
        </p>
        <p className={classes.policyText}>
          {translations.section_9.title}
        </p>
        <p className={classes.policyText}>
          {translations.section_10.title}
        </p>
        <p className={classes.policyText}>
          {translations.section_11.title}
        </p>
        <p className={classes.policyText}>
          {translations.section_12.title}
        </p>
        <p className={classes.policyText}>
          {translations.section_13.title}
        </p>
      </div>
  </Layout>
  )
}

PolicyPage.propTypes = {
  translations: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,

}

const useStyles = createUseStyles({
  policyPage: {
    maxWidth: '1200px',
    margin: 'auto',
    padding: '1em',
  },
  pageTitle: {
    backgroundColor: '#c1c2c4',
    height: '150px',
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    fontSize: '30px',

  },
  pageTitleWrapper: {
    margin: 'auto',
    maxWidth: '1221px',
    width: '100%',
    '& h1': {
      fontSize: '30px',
      fontWeight: '400',
      marginLeft: '1em',
    },
  },
  policyText: {
    margin: '20px 0',
    lineHeight: '120%',
    fontSize: '20px',
    color: '#535a57',
    '& a': {
      color: '#ff6f3a',
      textDecoration: 'none',
    },
  },
})

const mapStateToProps = (state) => {
  return {
    translations: state.translations.translations.policy_page,
  }
}

export default connect(mapStateToProps)(PolicyPage)
